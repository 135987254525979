<template>
    <barcode-scanner
        v-model="enabledScanner"
        :enabled="enabled"
        @input="searchByBarcode"
        @change="changeEnableScanner"
    />
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import BarcodeScanner from "@/components/utils/BarcodeScanner.vue";

export default {
    name: "InventoriesBarcodeScanner",
    components: { BarcodeScanner },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => false,
            type: Boolean,
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change", "input"],
    data: () => ({
        enabledScanner: false,
        isLoading: false
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        ...mapActions("inventories", ["getInventoryByBarcode"]),
        async searchByBarcode(barcode) {
            this.isLoading = true;
            try {
                const references = await this.getInventoryByBarcode({
                    barcode,
                    merchantType: this.selectedMerchant.merchantType,
                    merchantId: this.selectedMerchant.merchantId
                });
                if (!references.length) {
                    return this.$swal.fire({
                        background: this.backgroundColor,
                        title: "No encontró  referencia",
                        text: "No tienes la referencia en el inventario",
                        icon: "info"
                    });
                }
                this.$emit("input", references[0]);
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        setInput(value) {
            this.enabledScanner = Boolean(value);
        },
        changeEnableScanner() {
            this.$emit("change", this.enabledScanner);
        }
    }
};
</script>
