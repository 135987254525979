<template>
    <div class="border-bottom border-info">
        <div class="d-flex flex-wrap">
            <div
                class="col-12 d-flex flex-column justify-content-around py-3 px-1"
            >
                <div class="d-flex justify-content-between">
                    <span class="text-success text-capitalize p-0">
                        {{ `${user.firstName} ${user.lastName}` }}
                    </span>
                    <div class="d-flex">
                        <vs-button icon circle @click="onClick"> ✔️ </vs-button>
                        <button
                            v-show="slug"
                            icon
                            circle
                            class="btn-a"
                            @click="goToMainPage(slug)"
                        >
                            🖊
                        </button>
                    </div>
                </div>

                <div
                    class="d-flex flex-column flex-md-row justify-content-md-between"
                >
                    <div
                        class="d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Indentificación </strong>
                        <div class="d-flex align-items-center">
                            <small class="text-uppercase">
                                {{
                                    `${user.identificationType} ${user.identificationNumber}`
                                }}
                            </small>
                        </div>
                    </div>
                    <div
                        class="d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Correo </strong>
                        <small>
                            {{ user.email }}
                        </small>
                    </div>
                    <div
                        class="d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Celular </strong>
                        <small>
                            {{ user.phone }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserTableItems",
    props: {
        user: {
            type: Object,
            required: true,
            default: () => ({
                id: 9,
                email: "Cargando...",
                firstName: "Cargando...",
                identificationNumber: "Cargando...",
                identificationType: "cc",
                lastName: "Zamora",
                neighborhood: "Cargando...",
                phone: "Cargando..."
            })
        },
        slug: {
            type: String,
            default: () => "",
            required: false
        }
    },
    emits: ["click"],
    methods: {
        goToMainPage(slug) {
            window.open(`${slug}`, "_blank").focus();
        },
        onClick() {
            this.$emit("click", this.user);
        }
    }
};
</script>
