<template>
    <fragment>
        <div v-click-outside="hide" class="w-100 position-md-relative">
            <vs-input
                id="search-items"
                ref="serviceSearchInput"
                v-model="keySearch"
                border
                shadow
                autocomplete="off"
                type="text"
                name="service-name"
                placeholder="Buscar Servicio ..."
                class="w-100 bg-base"
                :disabled="disabledInput || !enabled"
                @input="onInputKeySearch"
                @focus="onFocusKeySearch"
                @blur="onBlur"
                @click-icon="requiredSearch"
                @keyup.esc="focus = -1"
                @keyup.enter="onEnter"
                @keyup.down="incrementFocus"
                @keyup.up="decrementFocus"
            >
                <template #icon> 🔎 </template>
            </vs-input>
            <div
                v-if="show"
                class="position-absolute z-40 px-4 mt-1 top-4 inset-x-0 md:left-auto origin-top-right md:origin-top"
            >
                <div
                    class="w-100 bg-base rounded pb-1 border-b border-right border-left border-secondary"
                >
                    <div class="overflow-auto h-max-96 w-40">
                        <div
                            v-for="(item, i) in optionsFiltered"
                            :key="i"
                            class="w-100 p-0"
                            :class="{ 'form-control': focus === i }"
                        >
                            <button
                                type="button"
                                class="w-100 btn rounded-0 mb-2 text-base"
                                @click="clickButton(i)"
                            >
                                {{ item.product.name | cut(20) }} |
                                {{ item.priceOffer | money }}
                            </button>
                        </div>

                        <div class="w-100 p-0">
                            <button
                                type="button"
                                class="w-100 btn rounded-0 mb-2 text-base"
                                @click="addService"
                            >
                                Agregar Servicio Nuevo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vs-dialog v-model="isOpenNewService" scroll overflow-hidden auto-width>
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex justify-content-center align-items-center flex-column flex-lg-row"
                >
                    <span class="h4">
                        Agrege servicios o productos anónimos
                    </span>
                </div>
            </template>
            <div class="con-content">
                <span>
                    Estas a punto de registrar una venta con un producto anómino
                    o una servicio no registrado. Recuerda solo deben de
                    registrase estas ventas para productos que no superen los $
                    COP 10.000 o en situaciones especiales, ingresa los
                    siguientes campos.
                </span>
                <div class="d-flex flex-wrap my-4">
                    <vs-input
                        v-model="name"
                        border
                        shadow
                        class="col-12"
                        label="Concepto"
                        name="concept"
                        autocomplete="off"
                        @focus="onFocusConcept"
                    />
                    <input-money-component
                        v-model="priceOffer"
                        border
                        class="col-12 mt-5"
                        enabled
                        label="Precio"
                        icon="💲"
                        @focus="onFocusName"
                    />
                </div>
                <div class="d-flex justify-content-center">
                    <vs-button
                        icon
                        :disabled="!priceOffer || !name"
                        @click="pushAnonymousProduct"
                    >
                        <span class="h4"> &nbsp;Agregar ✔️ </span>
                    </vs-button>
                </div>
            </div>
        </vs-dialog>
    </fragment>
</template>

<script>
import ClickOutside from "vue-click-outside";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ServiceSelector",
    directives: {
        ClickOutside
    },
    components: { InputMoneyComponent },
    props: {
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        base: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    emits: ["blur", "focus", "input"],
    data: () => ({
        keySearch: "",
        show: false,
        disabledInput: false,
        focus: -1,
        selectedKey: "",
        optionsFiltered: [],
        isOpenNewService: false,
        priceOffer: 1000,
        name: "No especifica concepto"
    }),
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        async advanceSearch(keyword) {
            if (!keyword) return;
            const keyLower = keyword.toLowerCase();
            this.optionsFiltered = this.base.filter(
                (item, index) =>
                    `${item.name}`.toLocaleLowerCase().includes(keyLower) &&
                    index < 3
            );
        },
        async onInputKeySearch() {
            this.show = !!this.keySearch;
            this.debounce(this.advanceSearch, 500, this.keySearch);
        },
        requiredSearch() {
            this.debounce(this.advanceSearch, 0, this.keySearch, true);
        },
        debounce(func, time, ...params) {
            if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                func(...params);
            }, time);
        },
        onBlur() {
            this.$emit("blur");
        },
        onFocusKeySearch() {
            if (this.selectedKey === this.keySearch) {
                this.keySearch = "";
            }
            this.optionsFiltered = this.base.slice(0, 4);
            this.show = true;
            this.$emit("focus");
        },
        emitEnter() {
            const item = this.optionsFiltered[this.focus];
            this.$emit("input", { ...item, quantity: 1 });
            this.selectedKey = `${item.product.name}`;
            this.focus = -1;
            this.show = false;
            this.disabledInput = true;
            setTimeout(() => {
                this.disabledInput = false;
            }, 300);
        },
        hide() {
            this.keySearch = `${this.selectedKey}`;
            this.show = false;
            this.focus = -1;
        },
        onEnter() {
            if (this.focus > -1) {
                this.emitEnter();
            } else {
                this.requiredSearch();
            }
        },
        clickButton(index) {
            this.focus = index;
            this.emitEnter();
        },
        incrementFocus() {
            if (this.focus === -1) {
                this.focus = 0;
            } else if (this.focus < this.optionsFiltered.length - 1) {
                this.focus = this.focus + 1;
            }
        },
        decrementFocus() {
            if (this.focus === -1) {
                this.focus = 0;
            } else if (this.focus > 0) {
                this.focus = this.focus - 1;
            }
        },
        addService() {
            this.isOpenNewService = true;
        },
        pushAnonymousProduct() {
            this.$emit("input", {
                quantity: 1,
                priceOffer: this.priceOffer,
                product: {
                    name: this.name
                }
            });
            this.isOpenNewService = false;
            this.name = "No especifica concepto";
        },
        onFocusConcept() {
            if (this.name === "No especifica concepto") {
                this.name = "";
            }
            this.$emit("focus");
        },
        onFocusName() {
            this.$emit("focus");
        }
    }
};
</script>
