<template>
    <fragment>
        <input
            v-show="showString"
            ref="inputMoneyString"
            v-model="priceString"
            type="text"
            class="vs-textarea col-12 mt-2 display-lg-1 display-md-2 display-4 text-base w-100 background-success"
            :disabled="!enabled"
            @focus="onFocus"
        />
        <input
            v-show="!showString"
            ref="inputMoneyNumber"
            v-model="priceNumber"
            type="text"
            class="vs-textarea col-12 mt-2 display-lg-1 display-md-2 display-4 text-base w-100 background-success"
            @blur="onBlur"
            @input="formatPrice"
            @keyup.enter="onEnter"
        />
    </fragment>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "InputMoneyComponent2",
    model: {
        prop: "itemCurrent",
        event: "input"
    },
    props: {
        itemCurrent: {
            default: 0,
            type: Number,
            required: true,
            nullable: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        autofocus: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["input", "blur", "enter"],
    data: () => ({
        priceString: "",
        priceNumber: 0,
        showString: true
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
        if (this.autofocus) {
            this.showString = false;
            setTimeout(() => {
                this.$refs.inputMoneyNumber.focus();
            }, 100);
        }
    },
    methods: {
        onFocus() {
            this.showString = false;
            setTimeout(() => {
                this.$refs.inputMoneyNumber.focus();
            }, 100);
            this.$emit("focus");
        },
        onBlur() {
            this.showString = true;
            this.$emit("blur");
        },
        onEnter() {
            this.$emit("enter");
        },
        formatPrice() {
            this.priceNumber = Number(this.priceNumber);
            this.priceString = this.$options.filters.currency(this.priceNumber);
            this.$emit("input", this.priceNumber);
        },
        setInput(value) {
            this.priceNumber = value;
            this.priceString = this.$options.filters.currency(value);
        }
    }
};
</script>
