<template>
    <div>
        <div v-show="errorString" class="mb-2">
            <span class="text-danger">
                {{ errorString }}
            </span>
        </div>
        <div v-show="isLoading" class="my-2">
            <span class="text-warning"> Cargando... </span>
        </div>
        <div v-if="isLoaded" class="col-12">
            <span v-if="users.length" class="mb-2">
                Usuarios en el alamacén
                <strong>
                    {{ users.length }}
                </strong>
            </span>
            <span v-else> No hay usuarios asociados en el comercio </span>
            <div class="col-12">
                <user-table-items
                    v-for="item in users"
                    :key="item.id"
                    :user="item"
                    :slug="`/app/users/detail/${item.id}`"
                    @click="selectUser"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

import UserTableItems from "./UserTableItems.vue";

export default {
    name: "UserByMerchantManage2",
    components: { UserTableItems },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        merchant: {
            type: Object,
            default: () => ({ merchantId: "", merchantType: "store" })
        },
        itemCurrent: {
            type: Object,
            default: () => ({
                email: "",
                firstName: "",
                id: 4,
                identificationNumber: "",
                identificationType: "cc",
                lastName: "",
                phone: "",
                updatedAt: "2022-03-28T01:01:34.813Z"
            }),
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        users: [],
        isLoaded: false,
        isLoading: false,
        errorString: ""
    }),
    watch: {
        merchant() {
            this.listItems();
        }
    },
    mounted() {
        this.listItems();
    },
    methods: {
        ...mapActions("users", ["listUsersMerchant"]),
        selectUser(user) {
            this.$emit("change", user);
        },
        async listItems() {
            this.isLoading = true;
            this.isLoaded = false;
            try {
                this.users = await this.listUsersMerchant(this.merchant);
            } catch (error) {
                this.errorString = `Error code ${error.statusCode}`;
            } finally {
                this.isLoading = false;
                this.isLoaded = true;
            }
        }
    }
};
</script>
